.words-list-item {
  width: 100%;
}

.words-list-item:hover {
  cursor: grab;
}

.words-list-item .MuiChip-label {
  overflow: visible;
}
